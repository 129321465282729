
export default {
  name: "LayoutConfiguracion",
  components: {
    menuConfiguraciones: () => import("../menus/MenuConfiguracion.vue"),
  },
  data() {
    return {
      menuConfiguraciones: true,
    };
  },
  methods: {
    salir() {
      this.$router.push("/home");
    },
  },
};
